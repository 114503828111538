import { render, staticRenderFns } from "./fprDetail.vue?vue&type=template&id=9067e8de&scoped=true&"
import script from "./fprDetail.vue?vue&type=script&lang=js&"
export * from "./fprDetail.vue?vue&type=script&lang=js&"
import style0 from "./fprDetail.vue?vue&type=style&index=0&id=9067e8de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9067e8de",
  null
  
)

export default component.exports